import * as Sentry from "@sentry/astro";
if (!import.meta.env.DEV && document.location.pathname.startsWith("/editor")) {
	Sentry.init({
		dsn: "https://25dae14309335c644bceef0aae147542@o4508176525295616.ingest.de.sentry.io/4508176528048208",

		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.feedbackIntegration({
				// Additional SDK configuration goes in here, for example:
				colorScheme: "dark",
				showBranding: false,
				triggerLabel: "Feedback melden",
				formTitle: "Feedback melden",
				showName: false,
				showEmail: false,
				messageLabel: "Beschreibung",
				isRequiredLabel: "(Pflichtfeld)",
				messagePlaceholder:
					"Beschreibe dein Problem oder deinen Vorschlag",
				successMessageText: "Danke für deine Nachricht!",
				confirmButtonLabel: "Bestätigen",
				cancelButtonLabel: "Abbrechen",
				submitButtonLabel: "Absenden",
				addScreenshotButtonLabel: "Screenshot anhängen",
				removeScreenshotButtonLabel: "Screenshot entfernen",
			}),
		],

		// Define how likely traces are sampled. Adjust this value in production,
		// or use tracesSampler for greater control.
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
	});
}
